import { Carousel, Row } from 'antd';
import Text from '../Ui/Typography/Text';
import React, { useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby';
import RightSmall from '../assets/right-small.png';
import RightSmallActive from '../assets/right-small-hover.png';
import { media } from '../Ui/text-sizes';
import RightActive from '../assets/right-active.png';
import RightHover from '../assets/right-big-hover.png';
import LeftActive from '../assets/left-active.png';
import LeftHover from '../assets/left-big-hover.png';

const GlobalStyle = createGlobalStyle`
    .slick-list {
        padding: 0 !important;
    }
    
    .left-arrow {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        left: -20px;
        top: 232px;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-image: url(${LeftActive});
        ${media.down('md')} {
            left: -14px;
            top: 148px;
        }
        
        ${media.down('sm')} {
            width: 32px;
            height: 32px;
            top: 68px;
        }
        &:hover{
            background-image: url(${LeftHover});
            border-radius: 100%;
        }
    }
    
    .item-portfolio {
        position: relative;
        max-width: 900px;
        margin-right: 30px;
        border-radius: 10px;
        overflow: hidden;
        ${media.down('md')} {
            max-width: 600px;
        }
        
        ${media.down('sm')} {
            max-width: 300px;
            margin-right: 12px;
        }
        
        &__right-arrow {
            position: absolute;
            z-index: 1;
            cursor: pointer;
            left: 879px;
            top: 232px;
            width: 40px;
            height: 40px;
            background-size: contain;
            background-image: url(${RightActive});
            &:hover{
                background-image: url(${RightHover});
                border-radius: 100%;
            }
            ${media.down('md')} {
                left: 579px;
                top: 148px;
            }
            
            ${media.down('sm')} {
                left: 281px;
                width: 32px;
                height: 32px;
                top: 68px;
            }

        }
        
        &__img {
            transition: all 0.3s;
        
            &:hover {
                transform: scale(1.02);
            }
            
            ${media.down('sm')} {
                border-radius: 0 !important;
            }
        }
    }
    
`;

const ProjectItems = ({ data }) => {

    const ref = useRef(null);
    function onChange(a, b, c) {
        setCurrent(a);
    }
    const contentStyle = {
        width: '100%',
        borderRadius: 10,
    };

    const [current, setCurrent] = useState(0);

    return <div style={{ position: 'relative' }}>
        <GlobalStyle />
        {current > 0 && <div onClick={() => ref.current.prev()} className='left-arrow' />}
        <Carousel dots={false} infinite={false} ref={ref} responsive={
            [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
        } variableWidth slidesToShow={1} slidesToScroll={1} afterChange={onChange}>
            {data.map((item, index) => {
                return <div className='item-portfolio'>
                    <img alt="new" src={item} style={contentStyle} className='item-portfolio__img' />
                </div>
            })}
        </Carousel>
        {current !== data.length - 1 && <div onClick={() => ref.current.next()} className='item-portfolio__right-arrow' />}
    </div>
};

export default ProjectItems;
