import React, { useEffect } from 'react';
import Container from '../components/container';
import SEO from '../components/seo';

import { Col, Row } from 'antd';
import Button from '../Ui/Buttons/index';
import { SearchOutlined } from '@ant-design/icons';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Title from '../Ui/Typography/Title';
import Text from '../Ui/Typography/Text';
import styled from 'styled-components';
import Section from '../components/Layout/Section';
import { media } from '../Ui/text-sizes';
import queryString from 'query-string'
import { StyledCard } from './our-portfolio';
import animateScrollTo from 'animated-scroll-to';
import ProjectItems from '../components/ProjectImages';
import { ImageLogo } from './our-portfolio';
import TestimonialImage from '../assets/quat.png';
import Colors from '../Ui/color';
const SingleProject = (props) => {
    const p = queryString.parse(props.location.search)?.p;

    useEffect(() => {
        animateScrollTo(document.querySelector('.project-title'))
    }, [])
    const { projects, feedback } = useStaticQuery(graphql`
        {
            projects: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portfolio/" } }) {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            id
                            img
                            logoBlack
                            slug
                            longTitle
                            idea
                            details
                            link
                            imgItems
                        }
                    }
                }
            }
            feedback: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/testimonials/"}}) {
                        edges {
                            node {
                                frontmatter {
                                    content
                                    autor
                                    autorImage
                                    position
                                    logo
                                    logoWidth
                                    slug
                                }
                            }
                        }
                }
        }

`);
    const mainData = projects.edges;
    const filteredData = mainData.filter(item => item.node.frontmatter.slug === p);

    useEffect(() => {
        if (!filteredData.length || !mainData.length || !p) {
            props.navigate('/404/');
        }
    }, [filteredData, p, mainData]);

    const [filteredFeedback] = feedback.edges.filter(item => item.node.frontmatter.slug === p);

    const [data] = filteredData;

    if (!data) {
        return null;
    }
    return (
        <Container defKey='2' title='Portfolio' titleLink={'/our-portfolio/'} second={data?.node?.frontmatter?.title} className={'project-title'}>
            <SEO
                title='Our portfolio' />
            <Section spaceTop={1} mobileTopSpace={5} >
                <CT xs={24} sm={13} lg={13}>
                    <Title level={2} color='darkGray'>
                        {data?.node?.frontmatter?.title}
                    </Title>
                </CT>
                <R>
                    {data.node.frontmatter.imgItems.length > 1 ?
                        <ProjectItems data={data.node.frontmatter.imgItems} /> :
                        <img src={data.node.frontmatter.img} width={'100%'} />}
                </R>
                <Text level='t4' upperSeperators colorType='orange'>
                    About the project
                </Text>
                <CLT xs={24} sm={20} lg={20}>
                    <Title level={2} color='darkGray'>
                        {data.node.frontmatter.longTitle}
                    </Title>
                </CLT>
                <Row gutter={30}>
                    <Col xs={24} sm={12} lg={12}>
                        <RT>
                            <Text level='t4' colorType='darkGray'>
                                The idea
                            </Text>
                        </RT>
                        <Row>
                            <Text level='t4' spaceTop={10}>
                                {data.node.frontmatter.idea}
                            </Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={12} lg={12}>
                        <RT>
                            <Text level='t4' colorType='darkGray'>
                                The details
                            </Text>
                        </RT>
                        <Row>
                            <Text level='t4' spaceTop={10}>
                                {data.node.frontmatter.details}
                            </Text>
                        </Row>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col lg={10} xs={24} sm={24} >
                        <Row >
                            <Seperator>
                                <Col style={{ borderTop: `1px solid ${Colors.orange}`, height: '1px', width: '146px', margin: 'auto auto' }} />
                            </Seperator>
                            <ContentTestimonial justify={'space-between'}>
                                <Col xs={24} sm={24} lg={4}>
                                    <Row justify={'center'}>
                                        <ImgTestimonial srcSet={`${TestimonialImage} 2x`} width={48} />
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} lg={19}>
                                    <Text level={'t3'} colorType={'black'}>{filteredFeedback.node.frontmatter.content.replaceAll('“', '').replaceAll('”', '')}</Text>
                                    <RowFooterTestimonial>
                                        <Col>
                                            <img src={filteredFeedback.node.frontmatter.autorImage} width='50' style={{ borderRadius: '100%', marginRight: 8, marginTop: 4 }} />
                                        </Col>
                                        <Col>
                                            <Col span={24}>
                                                <Text level='t3' colorType='darkGray'>{filteredFeedback.node.frontmatter.autor}</Text>
                                            </Col>
                                            <Col span={24}>
                                                <Text level='t3'>{filteredFeedback.node.frontmatter.position}</Text>
                                            </Col>
                                        </Col>
                                    </RowFooterTestimonial>
                                </Col>
                            </ContentTestimonial>
                        </Row>
                    </Col>
                </Row>
                <Section align='center' mobileBottomSpace={50} spaceTop={67} spaceBottom={1}>
                    <a href={data.node.frontmatter.link} target='__blank'>
                        <Button type='primary' shape='round'>Visit Live Site</Button>
                    </a>
                </Section>
            </Section>
            <Section secondary>
                <RP>
                    <Title level={2} color='darkGray'>
                        More partners
                    </Title>
                </RP>
                <Row gutter={[30, 30]}>
                    {mainData.filter(item => item.node.frontmatter.slug !== p).slice(0, 4).map((item, index) => <Col xs={24} sm={6}>
                        <a key={index} href={`/project/?p=${item.node.frontmatter.slug}`}>
                            <StyledCard>
                                <ImageLogo src={item.node.frontmatter.logoBlack} />
                            </StyledCard>
                        </a>
                    </Col>)}
                </Row>
                <Section align='center' mobileBottomSpace={50} spaceTop={46} spaceBottom={1}>
                    <Link to="/our-portfolio/">
                        <Button type='primary' shape='round'>All Portfolio</Button>
                    </Link>
                </Section>
            </Section>
        </Container >
    )
};
const RowFooterTestimonial = styled(Row)`
    margin-top: 40px;
    ${media.down('sm')} {
        justify-content: center;
    }
`
const ContentTestimonial = styled(Row)`
    margin-top: 60px;
    ${media.down('sm')} {
        margin-top: 30px;
    }
`
const Seperator = styled(Row)`
    width: 100%; 
    margin-top: 70px;
    ${media.down('sm')} {
        margin-top: 50px;
    }
`

const ImgTestimonial = styled.img`
    margin-top: 8px; 
    ${media.down('sm')} {
        margin-bottom: 16px;
    }
`
const RP = styled(Row)`
    margin-bottom: 30px

`;

const RT = styled(Row)`
    margin-top: 0px;
    
    ${media.down('sm')} {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const R = styled.div`
    margin-bottom: 60px;
    ${media.down('sm')} {   
        margin-bottom: 40px;
    }
`;

const CT = styled(Col)`
    margin-top: 19px;
    margin-bottom: 73px;
    
    ${media.down('sm')} {
        margin-top: 0px;
        margin-bottom: 30px;  
    }
`;

const CLT = styled(Col)`
    margin-top: 60px;
    margin-bottom: 59px;
    
    ${media.down('sm')} {
        margin-top: 30px;
        margin-bottom: 30px;  
    }
`;

export default SingleProject;
